import React, { useState } from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import SignIn from '../pages/auth/SignIn';
import { getLs, removeLs, putLs } from '../utils/storage';
import decode from 'jwt-decode';
import { getParam } from '../utils/window';

export const AuthContext = React.createContext({
  token: _initialToken(),
  setToken: () => {},
});

/**
 * We wrap routes that needs to be authenticated with this route
 *  */
export const withAuth = Component => {
  const hoc = React.forwardRef((props, ref) => (
    <AuthContext.Consumer>
      {({ token, setToken }) => {
        if (!token) {
          const redirect = new URL(window.location.href).pathname;
          return <SignIn setToken={setToken} redirect={redirect} />;
        }
        return <Component {...props} token={token} ref={ref} />;
      }}
    </AuthContext.Consumer>
  ));
  hoistNonReactStatics(hoc, Component);
  return hoc;
};

function AuthContextProvider({ children }) {
  const [token, setToken] = useState(_initialToken());

  const setTheToken = token => {
    if (!_isTokenValid(token)) return;

    putLs('token', token);
    setToken(token);
  };

  return (
    <AuthContext.Provider value={{ token, setToken: setTheToken }}>
      {children}
    </AuthContext.Provider>
  );
}

export default AuthContextProvider;

function _isTokenValid(token) {
  if (!token) return false;
  let decoded;
  try {
    decoded = decode(token);
  } catch (err) {
    return false;
  }
  const exp = decoded.exp;
  const twoHoursInSeconds = 2 * 60 * 60;
  // We divide by 1000 since Date.now returns miliseconds instead of seconds in javascript.
  // https://auth0.com/forum/t/reading-expiry-date-of-jwt-on-client/2033/5
  return exp - twoHoursInSeconds > Date.now() / 1000;
}

function _initialToken() {
  // Check if there is a token in the url
  const t = getParam('tok');
  if (t && _isTokenValid(t)) {
    putLs('token', t);
    return t;
  }

  // Check if there is a token in the localstorage
  const token = getLs('token');
  if (!token) return null;

  if (!_isTokenValid(token)) {
    removeLs('token');
    return null;
  }
  return token;
}
