import { flattenTranslationObject } from "./utils";
import { addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";

addLocaleData(en);

/** WARNING : The keys that are used cannot be refactored directly
 * if you want to change the name of an object, perform a full text search
 * with the full path camera.title.x to find all usage that you will have
 * to be updated manually */
const translations = {
  logout: "Signout",
  login: {
    title: "Log in",
    forgotBackToLogin: "Back to login",
    help: "Please use the same email and password than in the Keenoa mobile app.",
    welcome: "Hello!",
    forgotPassword: "Forgot Password?",
    forgotTitle: "Account recovery",
    forgotSubmit: "Next",
    forgotInstruction:
      "Enter your email adress. If a Keenoa account is linked to it, you will receive an email.",
    forgotConfirmation:
      "Great! Now, check your email at the address: {email}. You should receive a link to reset your password within 3 minutes. If you dont, it most likely means that your Keenoa account is linked to a different account.",
    forgotAssistance: "Need further assistance ? ",
    forgotContact: "Contact our support team (support@keenoa.co)",
    forgotBack: "Try another email",
    forgotHelpTitle: "I need help recovering my account",
    forgotConfirmationTitle: "Check your email",
    forgotHelpBody:
      "Hey team Keenoa 👋! \n\nI have some trouble accessing my Keenoa account, I tried reseting my password with the email: {email}, but it did not work.😥 \n\nCan you help me ?\n\n Thanks",
    submit: "Log In",
    noAccount: "Don't have an account?",
    signUp: "Create an account",
    email: "Email",
    password: "Password",
    passwordHelp: "Min 6 characters",
    success: "Login sucessful",
    error:
      "Sorry, we could not find your account with this email and password.",
    new: {
      title: "New password",
      instruction: "Please choose a new pasword below.",
      submit: "Set password",

      successTitle: "Success",
      successText:
        "You can now go back to your Keenoa mobile app and use your new password to access you Keenoa account. If you wanted to access your reports, you can use the button below to login into the web portal.",
      successSubmit: "Web connection",

      noToken: "Please use the link that you received by email.",
      badToken: "Your link has expired, please make a new request.",
    },
  },
  reports: {
    title: "Reports",
    empty: "Your dietitian has'nt generated a report for you yet.",
  },
  deleteAccount: {
    title: "Delete Keenoa Account",
    p1: "Your data collected through Keenoa can be deleted by permanently deleting your account. Please read the implications below carefully before proceeding. Please feel free to reach out to our support team at support@keenoa.com if you have any questions before proceeding.",
    p2: "1. Account deletion is permanent and irreversible. All your data, including your profile information, food diaries, and photos, will be permanently deleted from our servers.",
    check1: "I understand that I will not be able to recover my data.",
    p3: "2. Any dietitians or researchers who have access to your data will lose their access. This could impact their analysis or studies. We strongly encourage you to discuss this decision with them before proceeding.",
    check2:
      "I understand that my dietitian(s) or researcher(s) will be impacted.",
    p4: "To delete your account, please enter 'delete my account' in the box below.",
    confirm: "delete my account",
    button: "Permanently delete my Keenoa account",
    error:
      "Sorry, an error occured while processing your request. Please try again latter or reach out to our support team: support@keenoa.com.",
    success:
      "We have received your request successfully. Your account and all associated data will be permanently removed within the next 5 minutes.",
  },
  report: {
    download: "Download",
  },
};

export default flattenTranslationObject(translations);
