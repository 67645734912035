import React from 'react';
import { Link as RouterLink } from '@reach/router';
import styled from 'styled-components';
import Typography from './material/Typography';

const MyLink = styled(RouterLink)`
  color: ${x => x.theme.primary1};
  text-decoration: none;
  font-weight: 700;

  &:hover {
    cursor: pointer;
    color: ${x => x.theme.primary1Saturated};
  }
`;

const MyLinkDiv = styled.div`
  color: ${x => x.theme.primary1};
  text-decoration: none;
  font-weight: 700;

  &:hover {
    cursor: pointer;
    color: ${x => x.theme.primary1Saturated};
  }
`;

const MyLinkA = styled.a`
  color: ${x => x.theme.primary1};
  text-decoration: none;
  font-weight: 700;

  &:hover {
    cursor: pointer;
    color: ${x => x.theme.primary1Saturated};
  }
`;

export const Link = props => (
  <Typography use="body1">
    {props.to ? (
      <MyLink {...props}>{props.children}</MyLink>
    ) : props.href ? (
      <MyLinkA {...props}>{props.children}</MyLinkA>
    ) : (
      <MyLinkDiv {...props}>{props.children}</MyLinkDiv>
    )}
  </Typography>
);
export default Link;
