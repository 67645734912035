import React from "react";
import { removeLs, getLs } from "../utils/storage";
import { defineMessages, FormattedMessage } from "react-intl";
import ButtonIcon from "./ButtonIcon";

export function logout() {
  removeLs("token");
  window.location = "/signin";
}

const messages = defineMessages({
  logout: { id: "logout" },
});

function Logout() {
  const token = getLs("token");

  if (!token) return null;
  return (
    <div
      style={{
        position: "fixed",
        top: 15,
        right: 10,
        fontSize: 14,
      }}
      onClick={logout}
    >
      <ButtonIcon icon={["far", "sign-out"]}>
        <FormattedMessage {...messages.logout} />
      </ButtonIcon>
    </div>
  );
}

export default Logout;
