import React, { useState, useEffect } from 'react';
import { TextField } from '../../components/material/Texfield';
import styled from 'styled-components';
import Button from '../../components/material/Button';
import Typography from '../../components/material/Typography';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { insecureFetch, JSON_HEADER } from '../../utils/api';
import Auth from './Auth';
import { getParam } from '../../utils/window';
import { navigate } from '@reach/router';
import { notify } from 'react-notify-toast';

const messages = defineMessages({
  password: { id: 'login_password' },
  passwordHelp: { id: 'login_passwordHelp' },

  title: { id: 'login_new_title' },
  instruction: { id: 'login_new_instruction' },
  submit: { id: 'login_new_submit' },

  successTitle: { id: 'login_new_successTitle' },
  successText: { id: 'login_new_successText' },
  successSubmit: { id: 'login_new_successSubmit' },

  noToken: { id: 'login_new_noToken' },
  badToken: { id: 'login_new_badToken' },
});

const WrapperStyle = `
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 300px;
  justify-content: space-around;
  padding: 30px;
  background-color: white;
`;
const Form = styled.form`
  ${WrapperStyle}
  --mdc-theme-primary: ${x => x.theme.primary1};
`;
const Wrapper = styled.div`
  ${WrapperStyle}
  height: 350px;
  --mdc-theme-primary: ${x => x.theme.primary1};
`;

function NewPassword({ intl }) {
  const [password, setPassword] = useState('');
  const [passwordRef, setPasswordRef] = useState(null);
  const [token, setToken] = useState(null);
  const [sent, setSent] = useState(false);

  useEffect(() => {
    if (!passwordRef) return;
    setTimeout(() => setPasswordRef.focus(), 100);
  }, [setPasswordRef]);
  useEffect(() => {
    const p = getParam('token');
    if (!p) {
      notify.show(intl.formatMessage(messages.noToken), 'error');
      navigate('/signin/reset');
    }
    setToken(p);
  }, []);

  const submitNew = async () => {
    try {
      await insecureFetch('/accounts/reset-password', {
        method: 'post',
        headers: JSON_HEADER,
        body: JSON.stringify({ token, password }),
      });
    } catch (err) {
      notify.show(intl.formatMessage(messages.badToken), 'error');
      navigate('/signin/reset');
      return;
    }

    setSent(true);
  };

  const renderForm = () => (
    <Form
      onSubmit={e => {
        e.preventDefault();
        submitNew();
      }}
      onKeyPress={e => {
        if (e.key === 'Enter') {
          e.preventDefault();
          submitNew();
        }
      }}
    >
      <Typography use="headline4" rank="primary">
        <FormattedMessage {...messages.title} />
      </Typography>
      <Typography use="subtitle1" rank="primary">
        <FormattedMessage {...messages.instruction} />
      </Typography>
      <TextField
        outlined
        required
        inputRef={x => setPasswordRef(x)}
        label={intl.formatMessage(messages.password)}
        helpText={intl.formatMessage(messages.passwordHelp)}
        type="password"
        autoComplete="current-password"
        value={password}
        onChange={e => setPassword(e.target.value)}
      />
      <Button
        label={intl.formatMessage(messages.submit)}
        unelevated
        disabled={!password || password.length < 6}
        style={{ width: 150, marginLeft: 'auto' }}
      />
    </Form>
  );

  const renderConfirmation = () => (
    <Wrapper>
      <Typography use="headline4">
        <FormattedMessage {...messages.successTitle} />
      </Typography>
      <Typography use="subtitle1" rank="midPrimary">
        <FormattedMessage {...messages.successText} />
      </Typography>
      <Button
        label={intl.formatMessage(messages.successSubmit)}
        onClick={() => {
          setSent(false);
          navigate('/signin');
        }}
        outlined
        style={{ width: 175, marginLeft: 'auto' }}
      />
    </Wrapper>
  );

  return <Auth>{sent ? renderConfirmation() : renderForm()}</Auth>;
}

export default injectIntl(NewPassword);
