import { flattenTranslationObject } from "./utils";
import { addLocaleData } from "react-intl";
import fr from "react-intl/locale-data/fr";

addLocaleData(fr);

/** WARNING : The keys that are used cannot be refactored directly
 * if you want to change the name of an object, perform a full text search
 * with the full path camera.title.x to find all usage that you will have
 * to be updated manually */
const translations = {
  logout: "Déconnexion",
  login: {
    title: "Connexion",
    forgotBackToLogin: "Retour à la connexion",
    help: "Veuillez utiliser le même courriel et mot de passe que dans l'app mobile Keenoa.",
    welcome: "Salut!",
    forgotPassword: "Mot de passe oublié?",
    forgotTitle: "Récupération de compte",
    forgotSubmit: "Suivant",
    forgotInstruction:
      "Entrez votre adresse courriel. Si un compte Keenoa y est lié, vous recevrez un courriel.",
    forgotConfirmation:
      "Génial! Maintenant, vérifiez vos courriel à l'adresse: {email}. En moins de 3 minutes, vous devriez recevoir un lien pour réinitialiser votre mot de passe. Si ce n'est pas le cas, c'est probablement que votre compte Keenoa est lié à un courriel différent.",
    forgotAssistance: "Besoin de plus d'aide? ",
    forgotContact: "Contactez notre équipe (support@keenoa.co)",
    forgotBack: "Essayer une autre adresse",
    forgotHelpTitle: "J'ai besoin d'aide pour accéder à mon compte",
    forgotConfirmationTitle: "Vérifiez vos courriels",
    forgotHelpBody:
      "Bonjour à l'équipe Keenoa 👋! \n\nJ’ai des difficultés à accéder à mon compte Keenoa. J’ai essayé de récupérer mon mot de passe avec l’adresse {email} sans succès. 😥 \n\nPouvez-vous m'aider ?\n\n Merci!",
    submit: "Connexion",
    noAccount: "Nouveau sur Keenoa?",
    signUp: "Créer un compte",
    email: "Courriel",
    password: "Mot de passe",
    passwordHelp: "6 caractères min",
    success: "Connexion réussie",
    error:
      "Désolé, nous n’avons pu trouver votre compte avec ce courriel et ce mot de passe.",

    new: {
      title: "Nouveau mot de passe",
      instruction: "Veuillez choisir un nouveau mot de passe.",
      submit: "Modifier",

      successTitle: "Succès",
      successText:
        "Vous pouvez maintenant utilisé votre nouveau mot de passe pour vous connecter dans votre application mobile Keenoa.",
      successSubmit: "Connexion web",

      noToken: "Veuillez-vous assurer d'appuyer sur le lien reçu par courriel.",
      badToken: "Votre lien est expiré, veuillez faire une nouvelle demande.",
    },
  },
  reports: {
    title: "Rapports",
    empty: "Votre nutritionniste n'a pas encore généré de rapport pour vous.",
  },
  deleteAccount: {
    title: "Supprimer votre compte Keenoa",
    p1: "Vos données collectées via Keenoa peuvent être supprimées en effaçant votre compte de manière permanente. Veuillez lire attentivement les implications ci-dessous avant de procéder. N'hésitez pas à contacter notre équipe de support à l'adresse support@keenoa.com si vous avez des questions avant de procéder.",
    p2: "1. La suppression de compte est permanente et irréversible. Toutes vos données, y compris vos informations de profil, vos journaux alimentaires et vos photos, seront définitivement supprimées de nos serveurs.",
    check1: "Je comprends que je ne pourrai pas récupérer mes données.",
    p3: "2. Les nutritionnistes ou chercheurs qui ont accès à vos données perdront leur accès. Cela pourrait avoir un impact sur leurs analyses ou leurs études. Nous vous encourageons fortement à discuter de cette décision avec eux avant de continuer.",
    check2:
      "Je comprends que mes nutritionniste(s) ou chercheur(se)s seront impacté(e)s.",
    p4: "Pour supprimer votre compte, veuillez entrer 'supprimer mon compte' dans la case ci-dessous.",
    confirm: "supprimer mon compte",
    button: "Supprimer mon compte Keenoa de manière permanente",
    error:
      "Désolé, une erreur est survenue lors du traitement de votre demande. Veuillez réessayer plus tard ou contacter notre équipe de support : support@keenoa.com.",
    success:
      "Nous avons bien reçu votre demande. Votre compte et toutes les données associées seront définitivement supprimés dans les 5 prochaines minutes.",
  },
  report: {
    download: "Télécharger",
  },
};

export default flattenTranslationObject(translations);
