import React, { useState, useEffect } from 'react';

import { TextField } from '../../components/material/Texfield';
import styled from 'styled-components';
import Button from '../../components/material/Button';
import Typography from '../../components/material/Typography';
import { defineMessages, FormattedMessage, injectIntl } from 'react-intl';
import { insecureFetch, JSON_HEADER } from '../../utils/api';
import Link from '../../components/Link';
import mailtoLink from 'mailto-link';
import Auth from './Auth';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const messages = defineMessages({
  forgotTitle: { id: 'login_forgotTitle' },
  forgotSubmit: { id: 'login_forgotSubmit' },
  forgotInstruction: { id: 'login_forgotInstruction' },
  email: { id: 'login_email' },
  confirmation: { id: 'login_forgotConfirmation' },
  confirmationTitle: { id: 'login_forgotConfirmationTitle' },

  assistance: { id: 'login_forgotAssistance' },
  support: { id: 'login_forgotContact' },
  back: { id: 'login_forgotBack' },

  cancel: { id: 'login_forgotBackToLogin' },

  helpTitle: { id: 'login_forgotHelpTitle' },
  helpBody: { id: 'login_forgotHelpBody' },
});

const WrapperStyle = `
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 300px;
  justify-content: space-around;
  padding: 30px;
  background-color: white;
  position: relative;
`;
const Form = styled.form`
  ${WrapperStyle}
  --mdc-theme-primary: ${x => x.theme.primary1};
`;
const Wrapper = styled.div`
  ${WrapperStyle}
  height: 350px;
  --mdc-theme-primary: ${x => x.theme.primary1};
`;

const Back = styled(Link)`
  position: absolute;
  top: 22px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  padding: 3px 0;
  cursor: pointer;
  color: ${x => x.theme.primary1};
`;

const BackIcon = styled(FontAwesomeIcon)`
  font-size: 12px;
  margin-right: 5px;
`;

function SignIn({ intl, setToken }) {
  const [email, setEmail] = useState('');
  const [emailRef, setEmailRef] = useState(null);
  const [sent, setSent] = useState(false);

  useEffect(() => {
    if (!emailRef) return;

    setTimeout(() => emailRef.focus(), 100);
  }, [emailRef]);

  const submitForgot = async email => {
    await insecureFetch('/accounts/request-password', {
      method: 'post',
      headers: JSON_HEADER,
      body: JSON.stringify({ email }),
    });
    setSent(true);
  };

  const renderForm = () => (
    <Form
      onSubmit={e => {
        e.preventDefault();
        submitForgot(email);
      }}
      onKeyPress={e => {
        if (e.key === 'Enter') {
          e.preventDefault();
          submitForgot(email);
        }
      }}
    >
      <Back to="/signin">
        <BackIcon icon={['far', 'chevron-left']} />
        {intl.formatMessage(messages.cancel)}
      </Back>
      <Typography use="headline4" rank="primary">
        <FormattedMessage {...messages.forgotTitle} />
      </Typography>
      <Typography use="subtitle1" rank="primary">
        <FormattedMessage {...messages.forgotInstruction} />
      </Typography>
      <TextField
        outlined
        required
        inputRef={x => setEmailRef(x)}
        type="email"
        label={intl.formatMessage(messages.email)}
        autoComplete="email"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      <Button
        label={intl.formatMessage(messages.forgotSubmit)}
        unelevated
        disabled={!email}
        style={{ width: 90, marginLeft: 'auto' }}
      />
    </Form>
  );

  const renderConfirmation = () => (
    <Wrapper>
      <Typography use="headline4">
        <FormattedMessage {...messages.confirmationTitle} />
      </Typography>
      <Typography use="subtitle1" rank="midPrimary">
        <FormattedMessage {...messages.confirmation} values={{ email }} />
      </Typography>
      <Typography use="body1" rank="primary">
        <FormattedMessage {...messages.assistance} />
        <Link
          href={mailtoLink({
            to: 'support@keenoa.co',
            subject: intl.formatMessage(messages.helpTitle),
            body: intl.formatMessage(messages.helpBody, { email }),
          })}
        >
          <FormattedMessage {...messages.support} />
        </Link>
      </Typography>

      <Button
        label={intl.formatMessage(messages.back)}
        onClick={() => setSent(false)}
        outlined
        style={{ width: 175, marginLeft: 'auto' }}
      />
    </Wrapper>
  );

  return <Auth>{!sent ? renderForm() : renderConfirmation()}</Auth>;
}

export default injectIntl(SignIn);
