import React, { useEffect, useState } from 'react';
import { withAuth } from '../providers/AuthContext';
import { secureCall, json, imageProxyUrl } from '../utils/api';
import { Document, Page, pdfjs } from 'react-pdf';
import styled from 'styled-components';
import printJS from 'print-js';
import Elevation from '../components/material/Elevation';
import Button from '../components/material/Button';
import { Waypoint } from 'react-waypoint';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CircularProgress from '../components/material/CircularProgress';
import { Link } from '@reach/router';
import Typography from '../components/material/Typography';
import { defineMessages, FormattedMessage } from 'react-intl';
import { media } from '../utils/style';
import { useWindowSize, useFullScreen } from '../utils/hooks';

const messages = defineMessages({
  download: { id: 'report_download' },
});

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${
  pdfjs.version
}/pdf.worker.js`;

export const Iframe = React.memo(props => React.createElement('iframe', props));

function Loader({ width }) {
  return (
    <div
      style={{
        width,
        height: 792,
        backgroundColor: 'white',
        position: 'absolute',
        zIndex: 0,
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        marginTop: 15,
        paddingTop: 200,
      }}
    >
      <CircularProgress size="xlarge" />
    </div>
  );
}

const ToolbarButton = styled.div`
  padding: 15px;
  cursor: pointer;
  color: white;
`;

const Wrapper = styled.div`
  background-color: ${x => (x.fullScreen ? 'rgb(0, 31, 66)' : '#f7f9fa')};
  display: flex;
  position: relative;

  --mdc-theme-primary: ${x => x.theme.primary1Saturated};
`;

const DownloadButton = styled(Button)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
`;

const PageWrapper = styled.div`
  box-shadow: 0 4px 8px -2px rgba(27, 39, 51, 0.08);
  margin-bottom: 15px;
  margin-top: 15px;
  position: relative;
`;

const TopBar = styled(Elevation)`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 60px;
  background-color: white;
  z-index: 5;
`;

const DocumentWrapper = styled.div`
  height: ${x => (x.fullScreen ? '98vh' : 'calc(100vh - 40px)')};
  margin-top: ${x => (x.fullScreen ? 0 : '60px')};
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
  width: {
    x=>x.fullscreen?'100%': '838px';
  }
  display: flex;
  justify-content: center;
`;

const WaypointWrapper = styled.div`
  position: absolute;
  top: 20%;
  width: 100%;
`;

const ToolbarWrapper = styled.div`
  height: 40px;
  background-color: rgba(0, 43, 92, 0.72);
  color: white;
  border-radius: 6px;
  padding: 5px;
  position: fixed;
  bottom: 25px;
  left: 50%;
  width: 380px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  ${media.phone`
    display: none;
  `};
`;

const BackWrapper = styled(Link)`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -10px;
  padding: 20px;
  color: ${x => x.theme.text.primary};
`;
function Back() {
  return (
    <BackWrapper to="/reports">
      <FontAwesomeIcon
        icon={['far', 'chevron-left']}
        style={{ fontSize: 14 }}
      />
    </BackWrapper>
  );
}

const ReportNameWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 30px;
  color: ${x => x.theme.text.primary};
`;
function ReportName({ name }) {
  return (
    <ReportNameWrapper>
      <Typography use="subtitle2">{name}</Typography>
    </ReportNameWrapper>
  );
}

function Report(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [blob, setBlob] = useState(null);
  const [report, setReport] = useState(null);
  const [nPages, setNPages] = useState(null);
  const [scale, setScale] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [fullScreen, openFullscreen, closeFullscreen] = useFullScreen();
  const [showLoader, setShowLoader] = useState(true);
  const [name, setName] = useState('');
  const windowSize = useWindowSize();
  const pageWidth = Math.min(900, windowSize.width - 20);

  const _fetchReport = async () => {
    setIsLoading(true);
    const data = await secureCall(`/reports/${props.reportId}`).then(json);
    setReport(data);
    setName(data.title + '.pdf');

    const fetchPdfUrl = imageProxyUrl(data.pdf_url);
    const blobData = await fetch(fetchPdfUrl).then(x => x.blob());
    setBlob(blobData);
    setIsLoading(false);

    // We show the loader a bit longer to avoid a flickering
    // (shortly showing no page) and instead show the loader.
    setTimeout(() => setShowLoader(false), 500);
  };
  useEffect(() => {
    if (isLoading) return;

    _fetchReport();
  }, []);

  const _makeSetCurrentPage = i => ({ currentPosition }) => {
    if (currentPosition === Waypoint.above) return;

    setPageNumber(i);
  };

  // Based on this answer: https://stackoverflow.com/a/19328891/5059407
  const _downloadReport = () => {
    var a = document.createElement('a');
    document.body.appendChild(a);
    a.style = 'display: none';
    a.id = 'report-download-link';
    a.href = URL.createObjectURL(blob);
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(a.href);
    document.body.removeChild(a);
  };

  const _toggleFullsceen = () => {
    if (fullScreen) {
      closeFullscreen();
    } else {
      openFullscreen('pdf-report');
    }
  };

  const _makeScrollToPage = i => () => {
    var elmnt = document.getElementById(`page-${i}`);
    if (!elmnt) return;
    elmnt.scrollIntoView();
    // We add a small offset to show that it's at the top.
    document.getElementById('document-wrapper').scrollTop -= 50;
  };

  const _printReport = () => printJS(URL.createObjectURL(blob));

  const renderToolbar = () => (
    <ToolbarWrapper>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <ToolbarButton onClick={_makeScrollToPage(pageNumber - 1)}>
          <FontAwesomeIcon icon={['far', 'arrow-alt-square-up']} />
        </ToolbarButton>
        <div>
          {(pageNumber || 0) + 1}
          {' / '}
          {nPages || 1}
        </div>
        <ToolbarButton onClick={_makeScrollToPage(pageNumber + 1)}>
          <FontAwesomeIcon icon={['far', 'arrow-alt-square-down']} />
        </ToolbarButton>
      </div>
      <ToolbarButton onClick={() => setScale(scale * 0.9)}>
        <FontAwesomeIcon icon={['far', 'minus-circle']} />
      </ToolbarButton>
      <ToolbarButton onClick={() => setScale(scale * 1.1)}>
        <FontAwesomeIcon icon={['far', 'plus-circle']} />
      </ToolbarButton>
      <ToolbarButton onClick={_toggleFullsceen}>
        <FontAwesomeIcon icon={['far', 'expand-alt']} />
      </ToolbarButton>
      <ToolbarButton onClick={_printReport}>
        <FontAwesomeIcon icon={['far', 'print']} />
      </ToolbarButton>
    </ToolbarWrapper>
  );

  const renderCurrentPageTracker = index => (
    <WaypointWrapper>
      <Waypoint
        onEnter={_makeSetCurrentPage(index)}
        onLeave={_makeSetCurrentPage(index - 1)}
      />
    </WaypointWrapper>
  );

  const renderTopbar = () => (
    <TopBar z={1}>
      <Back />
      <ReportName name={name} />
      <DownloadButton unelevated onClick={_downloadReport}>
        <FormattedMessage {...messages.download} />
      </DownloadButton>
    </TopBar>
  );

  const renderPdfDocument = () => (
    <Document
      file={blob}
      onLoadSuccess={({ numPages }) => setNPages(numPages)}
      renderMode="svg" // Important for fast scaling
      loading={null}
      style={{ zIndex: 1 }}
    >
      {Array.from(new Array(nPages), (el, index) => (
        <PageWrapper id={`page-${index}`} key={`page_${index + 1}`}>
          {renderCurrentPageTracker(index)}
          <Page
            width={pageWidth}
            pageNumber={index + 1}
            scale={scale}
            loading={null}
          />
        </PageWrapper>
      ))}
    </Document>
  );

  return (
    <Wrapper id="pdf-report" fullScreen={fullScreen}>
      {!fullScreen && renderTopbar()}
      <DocumentWrapper id="document-wrapper" fullScreen={fullScreen}>
        {isLoading || !report ? (
          <Loader width={pageWidth} />
        ) : (
          <>
            {showLoader && <Loader width={pageWidth} />}
            {renderPdfDocument()}
            {renderToolbar()}
          </>
        )}
      </DocumentWrapper>
    </Wrapper>
  );
}

export default withAuth(Report);
