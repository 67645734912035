import React, { useState } from "react";
import { withAuth } from "../providers/AuthContext";
import { secureCall } from "../utils/api";
import styled from "styled-components";
import Typography from "../components/material/Typography";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import Logout, { logout } from "../components/Logout";
import { Checkbox } from "../components/material/Checkbox";
import { TextField } from "../components/material/Texfield";
import Button from "../components/material/Button";
import { notify } from "react-notify-toast";

const messages = defineMessages({
  title: { id: "deleteAccount_title" },
  p1: { id: "deleteAccount_p1" },
  p2: { id: "deleteAccount_p2" },
  check1: { id: "deleteAccount_check1" },
  p3: { id: "deleteAccount_p3" },
  check2: { id: "deleteAccount_check2" },
  p4: { id: "deleteAccount_p4" },
  confirm: { id: "deleteAccount_confirm" },
  button: { id: "deleteAccount_button" },
  error: { id: "deleteAccount_error" },
  success: { id: "deleteAccount_success" },
});

const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 550px;
  margin: auto;
  padding: 20px;

  --mdc-theme-secondary: ${(x) => x.theme.primary1};
  --mdc-theme-primary: ${(x) => x.theme.primary1};
`;
const ButtonWrapper = styled.div`
  --mdc-theme-primary: ${(x) => x.theme.error};
  --mdc-theme-error: ${(x) => x.theme.error};
`;

function DeleteAccount({ intl }) {
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [checkOne, setCheckOne] = useState(false);
  const [checkTwo, setCheckTwo] = useState(false);

  const deleteAccount = async () => {
    setIsLoading(true);
    try {
      await secureCall(`/delete-account`, { method: "POST" });
      notify.show(intl.formatMessage(messages.success), "success");
      setIsDeleted(true);
      setTimeout(() => {
        logout();
      }, 5000);
    } catch (e) {
      notify.show(intl.formatMessage(messages.error), "error");
    } finally {
      setIsLoading(false);
    }
  };

  const isConfirmMessageEnabled = checkOne && checkTwo;
  const isConfirmMessageValid =
    confirmMessage === intl.formatMessage(messages.confirm);

  const isButtonEnabled = isConfirmMessageEnabled && isConfirmMessageValid;

  return (
    <PageWrapper>
      <Logout />
      <div style={{ height: 50 }} />
      <Typography use="headline4">
        <FormattedMessage {...messages.title} />
      </Typography>
      <div style={{ height: 50 }} />
      <Typography use="body1">
        <FormattedMessage {...messages.p1} />
      </Typography>
      <div style={{ height: 25 }} />
      <Typography use="body2">
        <FormattedMessage {...messages.p2} />
      </Typography>
      <div style={{ height: 5 }} />
      <div style={{ display: "flex", alignSelf: "flex-start" }}>
        <Checkbox
          checked={checkOne}
          onChange={(evt) => setCheckOne(!!evt.currentTarget.checked)}
        >
          <FormattedMessage {...messages.check1} />
        </Checkbox>
      </div>
      <div style={{ height: 25 }} />
      <Typography use="body2">
        <FormattedMessage {...messages.p3} />
      </Typography>
      <div style={{ height: 5 }} />
      <div
        style={{
          display: "flex",
          alignSelf: "flex-start",
          position: "relative",
        }}
      >
        <Checkbox
          checked={checkTwo}
          onChange={(evt) => setCheckTwo(!!evt.currentTarget.checked)}
        >
          <FormattedMessage {...messages.check2} />
        </Checkbox>
      </div>
      <div style={{ height: 25 }} />
      <Typography use="body2" style={{ alignSelf: "flex-start" }}>
        <FormattedMessage {...messages.p4} />
      </Typography>
      <div style={{ height: 5 }} />
      <TextField
        required
        compact
        on
        style={{ alignSelf: "flex-start", width: "100%" }}
        disabled={!isConfirmMessageEnabled || isLoading || isDeleted}
        invalid={!isConfirmMessageValid}
        value={confirmMessage}
        onChange={(e) => setConfirmMessage(e.target.value)}
      />
      <div style={{ height: 25 }} />
      <ButtonWrapper>
        <Button
          danger
          outlined
          disabled={!isButtonEnabled || isLoading || isDeleted}
          onClick={deleteAccount}
        >
          <FormattedMessage {...messages.button} />
        </Button>
      </ButtonWrapper>
    </PageWrapper>
  );
}

export default injectIntl(withAuth(DeleteAccount));
