import { css } from 'styled-components';

export const MEDIA_SIZES = {
  giant: 1199,
  desktop: 991,
  tablet: 768,
  bigPhone: 575,
  phone: 450,
};

// iterate through the sizes and create a media template
export const media = Object.keys(MEDIA_SIZES).reduce((accumulator, label) => {
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = MEDIA_SIZES[label] / 16;
  accumulator[label] = (...args) => css`
    @media (max-width: ${emSize}em) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {});
