import { useState, useEffect } from 'react';
import { closeFullscreen, openFullscreen } from './window';
// Taken here: https://usehooks.com/useWindowSize/
export const useWindowSize = () => {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    };
  }

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    if (!isClient) {
      return false;
    }

    function handleResize() {
      setWindowSize(getSize());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
};

export function useFullScreen() {
  const [fullScreen, setFullscreen] = useState(false);

  useEffect(() => {
    document.addEventListener('webkitfullscreenchange', exitHandler, false);
    document.addEventListener('mozfullscreenchange', exitHandler, false);
    document.addEventListener('fullscreenchange', exitHandler, false);
    document.addEventListener('MSFullscreenChange', exitHandler, false);
    return () => {
      document.removeEventListener('webkitfullscreenchange', exitHandler);
      document.removeEventListener('mozfullscreenchange', exitHandler);
      document.removeEventListener('fullscreenchange', exitHandler);
      document.removeEventListener('MSFullscreenChange', exitHandler);
    };
  }, []);

  // thank you: https://stackoverflow.com/a/21767854/5059407
  function check() {
    if (!window.screenTop && !window.screenY) {
      return false;
    } else {
      return true;
    }
  }

  function exitHandler(e) {
    if (check()) {
      setFullscreen(false);
    }
  }

  function open(id) {
    setFullscreen(true);
    openFullscreen(id);
  }

  function close() {
    setFullscreen(false);
    closeFullscreen();
  }

  return [fullScreen, open, close];
}
