import React from "react";
import AuthContextProvider from "./AuthContext";
import { ThemeProvider } from "styled-components";
import { IntlProvider } from "react-intl";
import { getLs, putLs } from "../utils/storage";
import * as i18n from "../configs/locales";
import { light } from "../configs/themes";
import { getParam } from "../utils/window";
import { MaterialThemeProvider } from "../components/material/Theme";

export default ({ children }) => {
  const locale = _getLocale();
  return (
    <IntlProvider key={locale} locale={locale} messages={i18n[locale]}>
      <ThemeProvider theme={light}>
        <MaterialThemeProvider
          options={{
            primary: light.primary1,
            secondary: light.accent1,
          }}
        >
          <AuthContextProvider>{children}</AuthContextProvider>
        </MaterialThemeProvider>
      </ThemeProvider>
    </IntlProvider>
  );
};

function _getLocale() {
  const l = getParam("locale");
  if ((l && l === "fr") || l === "en") {
    putLs("locale", l);
    return l;
  }
  let locale = getLs("locale");
  if (!locale) {
    locale = "en";
    putLs("locale", locale);
  }
  return locale;
}
