import React from 'react';
import styled from 'styled-components';
import Elevation from '../../components/material/Elevation';

const PageWraper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 60px;
  padding: 10px;
  box-sizing: border-box;
`;

const Elev = styled(Elevation)`
  max-width: 400px;
  display: flex;
  flex: 1;
`;

export default function Auth({ children }) {
  return (
    <PageWraper>
      <Elev z={1}>{children}</Elev>
    </PageWraper>
  );
}
