import React, { useState, useEffect } from "react";
import { TextField } from "../../components/material/Texfield";
import styled from "styled-components";
import Button from "../../components/material/Button";
import Typography from "../../components/material/Typography";
import Link from "../../components/Link";
import { insecureFetch, JSON_HEADER, json } from "../../utils/api";
import { notify } from "react-notify-toast";
import { defineMessages, FormattedMessage, injectIntl } from "react-intl";
import { navigate } from "@reach/router";
import Auth from "./Auth";

const messages = defineMessages({
  title: { id: "login_title" },
  password: { id: "login_password" },
  email: { id: "login_email" },
  forgot: { id: "login_forgotPassword" },
  submit: { id: "login_submit" },
  error: { id: "login_error" },
  success: { id: "login_success" },
  help: { id: "login_help" },
});

const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
  min-height: 300px;
  justify-content: space-around;
  padding: 30px;
  background-color: white;

  --mdc-theme-primary: ${(x) => x.theme.primary1};
`;

function SignIn({ intl, setToken, redirect }) {
  if (!setToken) navigate("../", true);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailRef, setEmailRef] = useState(null);

  useEffect(() => {
    if (!emailRef) return;
    setTimeout(() => emailRef.focus(), 100);
  }, [emailRef]);

  async function submit(email, password) {
    const res = await insecureFetch("/clients/login", {
      method: "post",
      headers: JSON_HEADER,
      body: JSON.stringify({ email, password, shortLived: true }),
    })
      .then(json)
      .catch((err) => {
        setPassword("");
        notify.show(intl.formatMessage(messages.error), "error");
        return null;
      });
    if (!res) return;
    notify.show(intl.formatMessage(messages.success), "success");
    setToken(res.token);
    if (redirect) {
      navigate(redirect, true);
    } else {
      navigate("../", true);
    }
  }

  return (
    <Auth>
      <Form
        autoComplete="on"
        onSubmit={(e) => {
          e.preventDefault();
          submit(email, password);
        }}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
            submit(email, password);
          }
        }}
      >
        <Typography use="headline4">
          <FormattedMessage {...messages.title} />
        </Typography>
        <Typography use="caption" rank="secondary">
          <FormattedMessage {...messages.help} />
        </Typography>
        <TextField
          outlined
          required
          inputRef={(x) => setEmailRef(x)}
          type="email"
          label={intl.formatMessage(messages.email)}
          autoComplete="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          outlined
          required
          label={intl.formatMessage(messages.password)}
          type="password"
          autoComplete="current-password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Link to="/signin/reset">{intl.formatMessage(messages.forgot)}</Link>

        <Button
          label={intl.formatMessage(messages.submit)}
          unelevated
          disabled={!email || !password}
          style={{ width: 90, marginLeft: "auto" }}
        />
      </Form>
    </Auth>
  );
}

export default injectIntl(SignIn);
