export function flattenTranslationObject(translationsObject, root = '') {
  const SEPARATOR = '_';
  return Object.keys(translationsObject).reduce((returnObj, currentKey) => {
    const key = root ? `${root}${SEPARATOR}${currentKey}` : currentKey;
    const value = translationsObject[currentKey];
    return value instanceof Object
      ? Object.assign(returnObj, flattenTranslationObject(value, key))
      : Object.assign(returnObj, { [key]: value });
  }, {});
}
