const ls = () => {
  /* During tests, the window is obvisouly not defined.
   By setting this fake implementation we are simply avoiding
   having to mock the storage if its not what we are testing.
   */
  return (
    window.localStorage || {
      setItem: () => {},
      getItem: () => {},
      removeItem: () => {},
      clearn: () => {},
    }
  );
};

export function putLs(key, value) {
  ls().setItem(key, value);
}

export function getLs(key) {
  return ls().getItem(key);
}

export function removeLs(key) {
  return ls().removeItem(key);
}

export function clearLs() {
  ls().clear();
}
