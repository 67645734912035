import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowAltSquareUp,
  faArrowAltSquareDown,
  faPlusCircle,
  faMinusCircle,
  faPrint,
  faExpandAlt,
  faChevronLeft,
  faSignOut,
} from '@fortawesome/pro-regular-svg-icons';

export const init = () => {
  library.add(
    faArrowAltSquareDown,
    faArrowAltSquareUp,
    faPlusCircle,
    faMinusCircle,
    faPrint,
    faExpandAlt,
    faChevronLeft,
    faSignOut
  );
};
