import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Color from 'color';

const bg = props =>
  Color(props.palette.primary1)
    .alpha(0.33)
    .string();
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 3px 12px;
  align-items: center;
  color: rgba(0, 0, 0, 0.65);
  border-radius: 6px;
  transition: all 0.14 ease-in-out;
  margin-right: 0.5rem;
  ${x => (!x.selected ? '' : 'background-color: ' + bg(x))};

  &:hover {
    cursor: pointer;
    box-shadow: ${props =>
      props.selected ? 'inset 0 0 0 2px hsla(0,0%,0%,0.1)' : 'none'};
    background-color: ${props =>
      !props.selected ? 'rgba(0, 0, 0, 0.06)' : bg(props)};
  }
`;

const IconWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;

const TextWrapper = styled.div`
  font-weight: 500 !important;
  margin-top: 2px;
  margin-left: 0.25rem;
`;

export default React.memo(function IconButton({
  onClick,
  selected,
  icon,
  children,
  className,
}) {
  return (
    <Wrapper onClick={onClick} selected={selected} className={className}>
      <IconWrapper>
        <FontAwesomeIcon icon={icon} />
      </IconWrapper>
      <TextWrapper>{children}</TextWrapper>
    </Wrapper>
  );
});
