import React from "react";
import { Router, Redirect, Link } from "@reach/router";
import Providers from "./providers";
import Report from "./pages/Report";
import { light } from "./configs/themes";
import Notifications from "react-notify-toast";
import Reports from "./pages/Reports";
import DeleteAccount from "./pages/DeleteAccount";
import { init } from "./utils/icons";
import KeenoaLogo from "./logo.png";

import SignIn_ from "./pages/auth/SignIn";
import ResetPassword from "./pages/auth/ResetPassword";
import NewPassword from "./pages/auth/NewPassword";
import { withAuth } from "./providers/AuthContext";

const SignIn = withAuth(SignIn_);

init();

function Logo() {
  return (
    <Link
      to="/reports"
      style={{
        position: "fixed",
        top: 10,
        left: 10,
      }}
    >
      <img src={KeenoaLogo} width={33} height={33} alt="Keenoa logo" />
    </Link>
  );
}

const App = () => (
  <div>
    <Providers>
      <Notifications
        colors={{
          error: { backgroundColor: light.error },
          success: { backgroundColor: light.success },
          warning: { backgroundColor: light.warning },
          info: { backgroundColor: light.primary1 },
        }}
      />
      <Logo />
      <Router>
        <Redirect from="/" to="reports" />

        <Reports path="reports" />
        <Report path="reports/:reportId" />
        <DeleteAccount path="delete-account" />
        <SignIn path="signin" />
        <ResetPassword path="signin/reset" />
        <NewPassword path="signin/new" />
      </Router>
    </Providers>
  </div>
);

export default App;
