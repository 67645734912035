import React, { useEffect, useState } from 'react';
import { withAuth } from '../providers/AuthContext';
import { secureCall, json } from '../utils/api';
import { Link } from '@reach/router';
import styled from 'styled-components';
import Typography from '../components/material/Typography';
import { defineMessages, FormattedMessage } from 'react-intl';
import Logout from '../components/Logout';

const messages = defineMessages({
  title: { id: 'reports_title' },
  empty: { id: 'reports_empty' },
});

const PageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;
const ReportsWrapper = styled.div`
  max-width: 500px;
  width: 100%;
`;

const ReportRow = styled(Link)`
  padding: 15px;
  display: block;
  text-decoration: none;
  color: ${x => x.theme.text.primary};
  border-bottom: 1px solid rgba(0, 0, 0, 0.07);

  &:hover {
    cursor: pointer;
    color: ${x => x.theme.primary1Saturated};
  }
`;

const NoReport = styled(Typography)`
  color: ${x => x.theme.text.secondary};
`;

function Reports() {
  const [isLoading, setIsLoading] = useState(true);
  const [reports, setReports] = useState([]);

  const fetchReports = async () => {
    setIsLoading(true);
    const data = await secureCall(`/reports`).then(json);
    setIsLoading(false);
    setReports(data);
  };
  useEffect(() => {
    fetchReports();
  }, []);

  const formatDate = creation_date => {
    const d = new Date(creation_date);
    return `${d.toLocaleDateString()}`;
  };

  return (
    <PageWrapper>
      <Logout />
      <div style={{ height: 50 }} />
      <Typography use="headline4">
        <FormattedMessage {...messages.title} />
      </Typography>
      <div style={{ height: 50 }} />
      {!reports.length && !isLoading && (
        <NoReport>
          <FormattedMessage {...messages.empty} />
        </NoReport>
      )}
      {isLoading ? null : (
        <ReportsWrapper>
          {reports.map(report => (
            <ReportRow key={report.id} to={`${report.id}`}>
              <Typography use="subtitle1">
                {report.title}.pdf
                <span style={{ opacity: 0.33, float: 'right' }}>
                  {formatDate(report.creation_date)}
                </span>
              </Typography>
            </ReportRow>
          ))}
        </ReportsWrapper>
      )}
    </PageWrapper>
  );
}

export default withAuth(Reports);
