export default {
  primary1: '#4990E2',
  primary1Saturated: '#2E8FFF',
  primary2: '#37A475',
  primary3: '#015249',

  accent1: '#FF5252',

  warning: '#f1c40f',
  success: '#2ecc71',
  error: '#e74c3c',

  text: {
    primary: '#212121',
    midPrimary: '#474747',
    secondary: '#757575',
    tertiary: '#ABABAB',
  },
};
